
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { ISelectDeviceCard } from '@/assets/interface/selectDeviceCard'

@Options({
  props: {
    info: {
      type: Object as PropType<ISelectDeviceCard>,
      require: true
    }
  },
  computed: {
  },
  methods: {
    selectCamera (cameraId: string) {
      this.info.nextSelectedDeviceId = cameraId
    },
    close () {
      if (this.info.nextSelectedDeviceId !== this.info.selectedDeviceId) {
        this.$emit('onChangeCamera', this.info.nextSelectedDeviceId)
      }
      this.info.show = false
    }
  }
})
export default class SelectCameraCard extends Vue {
}
