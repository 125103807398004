
import { ref, Ref, watch } from 'vue'
import { Options, Vue } from 'vue-class-component'
import useUtils, { IUseUtils } from '@/composables/useUtils'
import useQrCode, { IUseQrCode } from '@/composables/useQrCode'
import { QRCodeToStringOptions } from 'qrcode'
import useEmitter from '@/composables/useEmitter'
import useIndexedDb from '@/composables/useIndexedDb'
import useGeneratingQrCodeObjectStore, { IUseGeneratingQrCodeObjectStore } from '@/composables/useGeneratingQrCodeObjectStore'
import { Emitter, EventType } from 'mitt'
import GeneratingHistoryDetailCard from '@/components/qrcode/GeneratingHistoryDetailCard.vue'

interface ShareData {
  files?: File[]
  text?: string
  title?: string
  url?: string
}

const useQrCodeGenerator = (utils: IUseUtils, qrCode: IUseQrCode, generatingQrCodeObjectStore: IUseGeneratingQrCodeObjectStore, emitter: Emitter<Record<EventType, unknown>>) => {
  const qrCodeSvg: Ref<string> = ref('')
  const qrCodeData: Ref<string> = ref('')
  const isProcessing: Ref<boolean> = ref(false)
  const showAction: Ref<boolean> = ref(false)
  const isAbleToShareQrcode: Ref<boolean> = ref(false)
  const debouncedGetSVGElementQrcode: {
    (data: string, options?: QRCodeToStringOptions, cb?: (result: string) => void): void
    clear(): void
  } = utils.debounce(qrCode.getSVGElementQrcode, 1000)
  const toggleShowAction = () => {
    showAction.value = !showAction.value
  }
  const getQrcodeShareObject = async (scale = 1): Promise<ShareData> => {
    const qrCodeBase64 = await qrCode.pGetImageDataQrCode(qrCodeData.value, {
      scale
    })
    const qrCodeBlob = await utils.getBlob(qrCodeBase64)
    const file = new File([qrCodeBlob], `qrcode-${new Date().getTime()}.webp`, { type: qrCodeBlob.type })
    return {
      title: 'Share Qrcode',
      files: [
        file
      ]
    }
  }
  const canShareQrcode = async () => {
    return utils.canShare(await getQrcodeShareObject())
  }
  const shareQrcode = async () => {
    await utils.share(await getQrcodeShareObject(50))
  }
  const saveQrcode = async () => {
    const link = document.createElement('a')
    document.body.appendChild(link)
    link.setAttribute('type', 'hidden')
    link.href = await qrCode.pGetImageDataQrCode(qrCodeData.value, {
      scale: 50
    })
    link.download = `qrcode-${new Date().getTime()}.webp`
    link.click()
    document.body.removeChild(link)
  }
  const saveQrcodeItemToHistoryList = async () => {
    try {
      await generatingQrCodeObjectStore.writeGeneratingQrCodeItem(
        {
          data: qrCodeData.value,
          timestamp: new Date().getTime()
        }
      )
      emitter.emit('$alert-popup:msg', 'Saved to the history list.')
      emitter.emit('$alert-popup:bgColor', 'bg-green-500')
      emitter.emit('$alert-popup:timeout', 3000)
      emitter.emit('$alert-popup:show')
    } catch (e: any) {
      emitter.emit('$alert-popup:msg', e.message)
      emitter.emit('$alert-popup:bgColor', 'bg-red-500')
      emitter.emit('$alert-popup:timeout', 3000)
      emitter.emit('$alert-popup:show')
    }
  }
  watch(showAction, async (value: boolean) => {
    if (value) {
      isAbleToShareQrcode.value = await canShareQrcode()
    }
  })
  watch(qrCodeData, (value: string) => {
    if (value) {
      isProcessing.value = true
      debouncedGetSVGElementQrcode(value, {}, (result: string) => {
        qrCodeSvg.value = result
        isProcessing.value = false
      })
    } else {
      qrCodeSvg.value = ''
      debouncedGetSVGElementQrcode.clear()
      isProcessing.value = false
      isAbleToShareQrcode.value = false
      showAction.value = false
    }
  })
  return {
    qrCodeSvg,
    qrCodeData,
    isProcessing,
    showAction,
    isAbleToShareQrcode,
    toggleShowAction,
    canShareQrcode,
    shareQrcode,
    saveQrcode,
    saveQrcodeItemToHistoryList
  }
}

@Options({
  components: {
    GeneratingHistoryDetailCard
  },
  data () {
    return {
      historyInfo: {
        historyList: [],
        show: false
      }
    }
  },
  methods: {
    async openHistory () {
      try {
        this.historyInfo.historyList = []
        await this.getHistory(true)
        this.historyInfo.show = true
      } catch (e: any) {
        this.emitter.emit('$alert-popup:msg', e.message)
        this.emitter.emit('$alert-popup:bgColor', 'bg-red-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      }
    },
    async getHistory (firstInit = false) {
      const data = await this.generatingQrCodeObjectStore.getGeneratingQrCode(
        this.historyInfo.historyList.length > 0 ? this.historyInfo.historyList.length : 0
      )

      if (data.length > 0) {
        this.historyInfo.historyList = [
          ...this.historyInfo.historyList,
          ...data
        ]
      } else if (!firstInit) {
        this.emitter.emit('$alert-popup:msg', 'No more items in database')
        this.emitter.emit('$alert-popup:bgColor', 'bg-yellow-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      }
    }
  }
})

export default class QrCodeGenerator extends Vue {
  emitter = useEmitter()
  indexDb = useIndexedDb()
  utils = useUtils()
  qrCode = useQrCode()
  generatingQrCodeObjectStore = useGeneratingQrCodeObjectStore(this.indexDb.db)
  qrCodeGenerator = useQrCodeGenerator(this.utils, this.qrCode, this.generatingQrCodeObjectStore, this.emitter)
}
