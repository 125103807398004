
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { IHistoryDetailCard } from '@/assets/interface/historyDetailCard'
import useUtils from '@/composables/useUtils'
import { IQrCodeHistory } from '@/composables/useQrCode'
import useIndexedDb from '@/composables/useIndexedDb'
import useScanningQrCodeObjectStore from '@/composables/useScanningQrCodeObjectStore'

interface IActionBlockShow {
  show: boolean
}

interface IActionBlock {
  action: IActionBlockShow
}

@Options({
  props: {
    info: {
      type: Object as PropType<IHistoryDetailCard>,
      require: true
    }
  },
  data () {
    return {
      displayingList: [],
      busy: false,
      firstInit: true
    }
  },
  watch: {
    'info.show' (val) {
      this.busy = true
      if (val && this.firstInit) {
        this.displayingList = (this.info as IHistoryDetailCard).historyList.map((value: IQrCodeHistory): IQrCodeHistory & IActionBlock => {
          return {
            ...value,
            action: {
              show: false
            }
          }
        })
        this.firstInit = false
      } else {
        this.displayingList = []
      }
      this.busy = false
    },
    async 'info.historyList' (val) {
      this.busy = true
      if (val.length > 0 && !this.firstInit) {
        const currentIndex = this.displayingList.length
        for (let index = currentIndex; index < (this.info as IHistoryDetailCard).historyList.length; index++) {
          this.displayingList.push(
            {
              ...(this.info as IHistoryDetailCard).historyList[index],
              action: {
                show: false
              }
            }
          )
        }
        this.$refs.scroller.onScrollerResize()
      }
      this.busy = false
    }
  },
  methods: {
    close () {
      this.info.historyList = []
      this.info.show = false
      this.firstInit = true
    },
    toggleShowAction (value: IQrCodeHistory & IActionBlock) {
      value.action.show = !value.action.show
    },
    async deleteQrcode (index: number) {
      try {
        await this.scanningQrCodeObjectStore.deleteScanningQrCodeItem(this.displayingList[index].id)
        this.displayingList.splice(index, 1)
        this.emitter.emit('$alert-popup:msg', 'Deleted')
        this.emitter.emit('$alert-popup:bgColor', 'bg-green-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      } catch (e: any) {
        this.emitter.emit('$alert-popup:msg', e.message)
        this.emitter.emit('$alert-popup:bgColor', 'bg-red-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      }
    }
  }
})
export default class HistoryDetailCard extends Vue {
  utils = useUtils()
  indexDb = useIndexedDb()
  scanningQrCodeObjectStore = useScanningQrCodeObjectStore(this.indexDb.db)
}
