
import { Options, Vue } from 'vue-class-component'
import AlertPopup from '@/components/common/AlertPopup.vue'
import NaviBar from '@/components/common/NaviBar.vue'

@Options({
  components: {
    AlertPopup,
    NaviBar
  }
})

export default class App extends Vue {
}
