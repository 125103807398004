
import { Ref, ref, watch } from 'vue'
import { Vue, Options } from 'vue-class-component'
import { useRoute } from 'vue-router'
import useEmitter from '@/composables/useEmitter'
import { Emitter, EventType } from 'mitt'

interface IMenu {
  label: string
  cb: any
}

const usePopupMenu = (emitter: Emitter<Record<EventType, unknown>>) => {
  const route = useRoute()
  const show : Ref<boolean> = ref(false)
  const containerShow : Ref<boolean> = ref(true)
  const menus : Ref<IMenu[]> = ref([])
  watch(route, (newRoute) => {
    if (newRoute.name === 'Home') {
      show.value = false
      containerShow.value = true
      menus.value.push(
        {
          label: 'Upload Image',
          cb () {
            emitter.emit('$upload-popup:open')
          }
        }
      )
    } else {
      show.value = false
      containerShow.value = false
      menus.value = []
    }
  })
  return {
    show,
    menus,
    containerShow
  }
}

@Options({
  methods: {
    isActive (routeName: string) {
      return this.$route.name === routeName
    },
    close (e: any) {
      if (!this.$refs.popupWrap.contains(e.target)) {
        this.popup.show = false
      }
    }
  },
  watch: {
    'popup.containerShow' (value: boolean) {
      if (value) {
        document.addEventListener('click', this.close)
      } else {
        document.removeEventListener('click', this.close)
      }
    }
  },
  mounted () {
    if (this.popup.containerShow) {
      document.addEventListener('click', this.close)
    }
  },
  beforeDestroy () {
    if (this.popup.containerShow) {
      document.removeEventListener('click', this.close)
    }
  }
})

export default class NaviBar extends Vue {
  emitter = useEmitter()
  popup = usePopupMenu(this.emitter)
}
