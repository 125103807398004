
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { IGeneratingHistoryDetailCard } from '@/assets/interface/generatingHistoryDetailCard'
import useUtils from '@/composables/useUtils'
import useQrCode, { IQrCodeHistory } from '@/composables/useQrCode'
import useGeneratingQrCodeObjectStore from '@/composables/useGeneratingQrCodeObjectStore'
import useIndexedDb from '@/composables/useIndexedDb'

interface IActionBlockItem {
  show: boolean,
  isAbleToShareQrcode: boolean
}

interface IActionBlock {
  action: IActionBlockItem
}

interface IHtmlBlockSvg {
  svg: string
}

interface IHtmlBlock {
  html: IHtmlBlockSvg
}

interface ShareData {
  files?: File[]
  text?: string
  title?: string
  url?: string
}

@Options({
  props: {
    info: {
      type: Object as PropType<IGeneratingHistoryDetailCard>,
      require: true
    }
  },
  data () {
    return {
      displayingList: [],
      busy: false,
      firstInit: true
    }
  },
  watch: {
    async 'info.show' (val) {
      this.busy = true
      if (val && this.firstInit) {
        this.displayingList = (this.info as IGeneratingHistoryDetailCard).historyList.map((value: IQrCodeHistory): IQrCodeHistory & IActionBlock & IHtmlBlock => {
          return {
            ...value,
            action: {
              show: false,
              isAbleToShareQrcode: false
            },
            html: {
              svg: ''
            }
          }
        })
        for (let index = 0; index < this.displayingList.length; index++) {
          this.displayingList[index].html.svg = await this.qrCode.pGetSVGElementQrcode(this.displayingList[index].data)
          this.displayingList[index].action.isAbleToShareQrcode = this.utils.canShare(await this.getQrcodeShareObject(this.displayingList[index].data))
        }
        this.firstInit = false
      } else {
        this.displayingList = []
      }
      this.busy = false
    },
    async 'info.historyList' (val) {
      this.busy = true
      if (val.length > 0 && !this.firstInit) {
        const currentIndex = this.displayingList.length
        for (let index = currentIndex; index < (this.info as IGeneratingHistoryDetailCard).historyList.length; index++) {
          this.displayingList.push(
            {
              ...(this.info as IGeneratingHistoryDetailCard).historyList[index],
              action: {
                show: false,
                isAbleToShareQrcode: false
              },
              html: {
                svg: ''
              }
            }
          )
        }
        for (let index = currentIndex; index < this.displayingList.length; index++) {
          this.displayingList[index].html.svg = await this.qrCode.pGetSVGElementQrcode(this.displayingList[index].data)
          this.displayingList[index].action.isAbleToShareQrcode = this.utils.canShare(await this.getQrcodeShareObject(this.displayingList[index].data))
        }
        this.$refs.scroller.onScrollerResize()
      }
      this.busy = false
    }
  },
  methods: {
    close () {
      this.info.historyList = []
      this.info.show = false
      this.firstInit = true
    },
    toggleShowAction (value: IQrCodeHistory & IActionBlock) {
      value.action.show = !value.action.show
    },
    async getQrcodeShareObject (value: string, scale = 1): Promise<ShareData> {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const qrCodeBase64 = await this.qrCode.pGetImageDataQrCode(value, {
            scale
          })
          const qrCodeBlob = await this.utils.getBlob(qrCodeBase64)
          const file = new File([qrCodeBlob], `qrcode-${new Date().getTime()}.webp`, { type: qrCodeBlob.type })
          resolve({
            title: 'Share Qrcode',
            files: [
              file
            ]
          })
        }, 300)
      })
    },
    async shareQrcode (index: number) {
      return await this.utils.share(await this.getQrcodeShareObject(this.displayingList[index].data, 50))
    },
    async saveQrcode (index: number) {
      const link = document.createElement('a')
      document.body.appendChild(link)
      link.setAttribute('type', 'hidden')
      link.href = await this.qrCode.pGetImageDataQrCode(this.displayingList[index].data, {
        scale: 50
      })
      link.download = `qrcode-${new Date().getTime()}.webp`
      link.click()
      document.body.removeChild(link)
    },
    async deleteQrcode (index: number) {
      try {
        await this.generatingQrCodeObjectStore.deleteGeneratingQrCodeItem(this.displayingList[index].id)
        this.displayingList.splice(index, 1)
        this.emitter.emit('$alert-popup:msg', 'Deleted')
        this.emitter.emit('$alert-popup:bgColor', 'bg-green-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      } catch (e: any) {
        this.emitter.emit('$alert-popup:msg', e.message)
        this.emitter.emit('$alert-popup:bgColor', 'bg-red-500')
        this.emitter.emit('$alert-popup:timeout', 3000)
        this.emitter.emit('$alert-popup:show')
      }
    }
  }
})
export default class GeneratingHistoryDetailCard extends Vue {
  indexedDb = useIndexedDb()
  utils = useUtils()
  qrCode = useQrCode()
  generatingQrCodeObjectStore = useGeneratingQrCodeObjectStore(this.indexedDb.db)
}
