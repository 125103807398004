
import { PropType } from 'vue'
import { Options, Vue } from 'vue-class-component'
import { IDetailCard } from '@/assets/interface/detailCard'
import useUtils from '@/composables/useUtils'

@Options({
  props: {
    info: {
      type: Object as PropType<IDetailCard>,
      require: true
    }
  },
  computed: {
  },
  methods: {
    close () {
      this.info.qrcode = ''
      this.info.show = false
    }
  }
})
export default class DetailCard extends Vue {
  utils = useUtils()
}
